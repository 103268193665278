body {
    background:#2B2F32;
}
@import url("//hello.myfonts.net/count/397c98");

@font-face {font-family: 'MontanaTypeface';src: url('../webfonts/montana/397C98_0_0.eot');src: url('../webfonts/montana/397C98_0_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/montana/397C98_0_0.woff2') format('woff2'),url('../webfonts/montana/397C98_0_0.woff') format('woff'),url('../webfonts/montana/397C98_0_0.ttf') format('truetype'),url('../webfonts/montana/397C98_0_0.svg#wf') format('svg');}

$font-family-serif: 'Noto Serif', serif;
$font-family-secondary: $font-family-serif;
// Using the screensmart version but may need two different variables for non h1-h4 bold instances
$font-family-sans-serif: "Gotham XNarrow A", "Gotham XNarrow B";
// $font-family-sans-serif: "Gotham Narrow A", "Gotham Narrow B";
$font-family-primary: $font-family-sans-serif;

$font-script:'MontanaTypeface';