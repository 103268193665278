.ClosingsPage {

    #closings-filter-form {
        display:inline-block;
        width:100%;
        @include breakpoint(medium) {
            width:auto;
        }

    }

    .header-left {
        display:flex;
        align-items:center;
        .heading {
            margin:0px;
        }
    }
    .header-right {
        padding-top:10px;
        padding-bottom:10px;
        @include breakpoint(large) {
            text-align: right;
            padding-top:0px;
            padding-bottom:0px;
            }

        .button {
            background:#2b2f32;
            color:$primary-color;
            border:2px solid $primary-color;
            margin-top:10px;
            margin-right:0px;
            font-size:15px;
            @include breakpoint(medium) {
                font-size:18px;
                margin-right:5px;
            }

            @include breakpoint(xlarge) {
                margin-right:0px;
                margin-left:10px;
                margin-top:0;
                font-size: 24px;
                height: 60px;
                padding: 17px 26px;
            }
            &:first-child {
                margin-left:0;
            }
            &:last-child {
                margin-right:0;
            }

            &:hover {
                background:$primary-color;
                color:#fff;
            }
    
            &.active {
                background:$primary-color;
                color:#fff;
            }
        }
    
    }

    .nav-wrap {
        margin:10px 0;
        @include breakpoint(medium) {
            display:inline-flex;
        }
    }

    .date-dropdown {
        font-weight:900;
        width:100%;
        height:62px;
        padding-left:20px;
        margin-bottom:0px;
        @include breakpoint(medium) {
            width:200px;
            margin-right:20px;
        }
    }
    // .nav {
    //     display:inline-flex;
    // }

    .closings-table {
        thead {
            background:$primary-color;
            color:#fff;

            th {
                font-weight:900;
                background:$primary-color;
                vertical-align:middle;
                font-size:16px;
                line-height:20px;
                @include breakpoint(medium) {
                    font-size:18px;
                    line-height:22px;
                }
                @include breakpoint(large) {
                    font-size:28px;
                    line-height:32px;
                }
            }
        }

        tr {
            height:89px;

        }
        th,td {
            padding:0 10px;
            @include breakpoint(medium) {
                padding:0 15px;
            }
            @include breakpoint(large) {
                padding:0 30px;
            }
        }

        tbody {
            td {
                font-size:14px;
                line-height:20px;
                @include breakpoint(large) {
                    font-size:22px;
                    line-height:28px;
                }
            }
            tr:nth-child(even) {
                background:#E6E9EC;
            }
            tr:nth-child(odd) {
                background:#fff;
            }

        }

        .title {
            font-weight:700;
            color:#000;
        }
        .time {
            text-transform:capitalize;
        }
    }

    .sortable thead th:not(.no-sort)::before {
        display:none;
    }
    .sortable thead th:not(.no-sort)::after{
        opacity:0;
        position: relative;
        top: -2px;
        right: -10px;
        margin:0px;
        padding-left:7px;
        content:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjM4MjQ3IDcuNjY1MjVDNy44OTQzOCA4LjExMTU4IDcuMTAxNzIgOC4xMTE1OCA2LjYxMzYzIDcuNjY1MjVMMC4zNjYwNjcgMS45NTIyNEMtMC4xMjIwMjMgMS41MDU5MSAtMC4xMjIwMjMgMC43ODEwNzYgMC4zNjYwNjggMC4zMzQ3NDdDMC44NTQxNTggLTAuMTExNTgyIDEuNjQ2ODIgLTAuMTExNTgyIDIuMTM0OTEgMC4zMzQ3NDdMNy41IDUuMjQwNzlMMTIuODY1MSAwLjMzODMxOUMxMy4zNTMyIC0wLjEwODAxIDE0LjE0NTggLTAuMTA4MDEgMTQuNjMzOSAwLjMzODMxOUMxNS4xMjIgMC43ODQ2NDcgMTUuMTIyIDEuNTA5NDkgMTQuNjMzOSAxLjk1NTgyTDguMzg2MzcgNy42Njg4Mkw4LjM4MjQ3IDcuNjY1MjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
    }
    .sortable thead th:not(.no-sort)[aria-sort=descending]::after {
        content:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjM4MjQ3IDAuMzM0NzQ3QzcuODk0MzggLTAuMTExNTgyIDcuMTAxNzIgLTAuMTExNTgyIDYuNjEzNjMgMC4zMzQ3NDdMMC4zNjYwNjcgNi4wNDc3NkMtMC4xMjIwMjMgNi40OTQwOSAtMC4xMjIwMjMgNy4yMTg5MiAwLjM2NjA2OCA3LjY2NTI1QzAuODU0MTU4IDguMTExNTggMS42NDY4MiA4LjExMTU4IDIuMTM0OTEgNy42NjUyNUw3LjUgMi43NTkyMUwxMi44NjUxIDcuNjYxNjhDMTMuMzUzMiA4LjEwODAxIDE0LjE0NTggOC4xMDgwMSAxNC42MzM5IDcuNjYxNjhDMTUuMTIyIDcuMjE1MzUgMTUuMTIyIDYuNDkwNTEgMTQuNjMzOSA2LjA0NDE4TDguMzg2MzcgMC4zMzExNzdMOC4zODI0NyAwLjMzNDc0N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
        opacity:1;
    }
    .sortable thead th:not(.no-sort)[aria-sort=ascending]::after {
        content:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjM4MjQ3IDcuNjY1MjVDNy44OTQzOCA4LjExMTU4IDcuMTAxNzIgOC4xMTE1OCA2LjYxMzYzIDcuNjY1MjVMMC4zNjYwNjcgMS45NTIyNEMtMC4xMjIwMjMgMS41MDU5MSAtMC4xMjIwMjMgMC43ODEwNzYgMC4zNjYwNjggMC4zMzQ3NDdDMC44NTQxNTggLTAuMTExNTgyIDEuNjQ2ODIgLTAuMTExNTgyIDIuMTM0OTEgMC4zMzQ3NDdMNy41IDUuMjQwNzlMMTIuODY1MSAwLjMzODMxOUMxMy4zNTMyIC0wLjEwODAxIDE0LjE0NTggLTAuMTA4MDEgMTQuNjMzOSAwLjMzODMxOUMxNS4xMjIgMC43ODQ2NDcgMTUuMTIyIDEuNTA5NDkgMTQuNjMzOSAxLjk1NTgyTDguMzg2MzcgNy42Njg4Mkw4LjM4MjQ3IDcuNjY1MjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
        opacity:1;
    }
}