.app-wrap {
    overflow:hidden;
    transition:all .3s;
    padding-top:75px;

    &.app-view {
        padding-top:0px !important;
    }
    &.show-alert {
        padding-top:115px;
        #header {
            top:40px;
        }

        .alert-bar {
            transform:translateY(0px) !important;
        }
    }

    &.show-mobile-alert {
        @include breakpoint(small only) {

            padding-top:115px;
            #header {
                top:40px;
            }

            .mobile-alert-bar {
                transform:translateY(0px) !important;
            }
        }
    }
    .mobile-alert-bar {
        @include breakpoint(medium) {
            display:none !important;
        }
    }
}
.menu-open {
    overflow:hidden;

    .app-wrap {
        filter:blur(15px);
        &:before {
            content: '';
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.5);
            z-index: 11;
        
        }
    }
    .menu-corner-content {
        @include breakpoint(large) {
            display:block !important;
        }
    }
}
.flyout-menu {
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    z-index:-1;

    @include breakpoint(large) {
        background:transparent;
    }

    &.open {
        overflow-y:scroll;
        .main-col {
            transform:translateX(0%);
        }

        .desktop-search {
            @include breakpoint(large) {
                display:block;
                right:50px;
            }
        }
    }

    .logo {
        position: relative;
        z-index:10;
        width:115px;
        @include breakpoint(large) {
            width:210px;
            margin-bottom:50px;
        }
    }

    .main-col {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        width:100%;
        height:100%;
        padding:20px 0px 20px;
        background:$brand-dark;
        transition:all .3s;
        transform:translateX(-100%);
        max-width:550px;

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 100%;
            height: 100%;
            background:$brand-dark;
            z-index: 3;
            top: 0px;
        }

        @include breakpoint(large) {
            width:526px;
            max-width:none;
            padding:30px 0px 70px;
        }
    }
    ul {
        list-style-type:none;
        padding:0px;
        margin:0px;
        background-color: $brand-dark;
        @include breakpoint(large) {
            background:transparent;
        }
    }
    .l1-item {
        position:relative;
        @include breakpoint(large) {
            position:unset;
        }
        &:hover {
            .submenu {
                @include breakpoint(large) {
                    transform: translateX(100%);
                }
            }
            .l1-link {
                color:$primary-color;
                &:before {
                    height:100%;
                    background:$primary-color;
                }
            }
        }
        .btn-expand-menu {
            display:block;
            position: absolute;
            z-index: 10;
            right: 0px;
            top:0px;
            font-size: 36px;
            font-weight: 700;
            color: $secondary-color;
            padding:0px 20px;
            @include breakpoint(large) {
                display:none;
            }
        }
    }
    .l1-link {
        position:relative;
        color: #fff;
        font-size: 36px;
        line-height: 40px;
        font-style: normal;
        font-weight:900;
        display: block;
        padding: 12px 20px;
        z-index:5;
        transition:all .2s;
        background:$brand-dark;
        text-transform:uppercase;
        @include breakpoint(large) {
            padding:12px 50px;
            font-size: 48px;
            line-height: 50px;
        }
        &:before {
            content:'';
            position: absolute;
            width:8px;
            height:0px;
            left:0px;
            top:50%;
            transform:translateY(-50%);
            background:transparent;
            transition:all .2s;
        }
    }

    #btn-close-menu {
        position: absolute;
        left:0px;
        top:0px;
        z-index:10;
        display:block;
        margin-bottom:50px;
        padding:22px 20px;
        @include breakpoint(large) {
            position: relative;
            padding:0px;
        }
    }

    .menu-top-inner, .menu-btm {
        padding:0 20px;
        display:flex;
        justify-content:center;
        flex-wrap:wrap;
        @include breakpoint(large) {
            display:block;
            padding:0 50px;
        }
    }

    .menu-btm {
        position: relative;
        z-index:10;
        display:flex;
        justify-content:space-between;
        background:$brand-dark;
        padding-top:30px;
        .button {
            padding:25px 38px;
            width:100%;
            @include breakpoint(large) {
                width:auto;
            }
        }

        .app-social-wrap {
            display:block;
            padding-top:30px;
            margin:0 auto;
            text-align:center;

            @include breakpoint(large) {
                display:none;
            }
            img {
                margin-top:10px;
                width:calc(50% - 2px);
                max-width:190px;
            }
        }
        .social-links {
            width:100%;
            justify-content: center;
            a {
                justify-content: center;
                margin:0px;
                padding: 0 15px;
                color:$secondary-color;
                font-size:24px;
            }
            svg {fill:$secondary-color;}
        }
    }

    .submenu {
        z-index:10;
        position:relative;
        transition:all .4s;
        max-height:0px;
        overflow: hidden;
        &.open {
            max-height:400px;
            @include breakpoint(large) {
                max-height:100%;
            }
        }
        @include breakpoint(large){
            background:$primary-color;
            position:absolute;
            right:0px;
            top:0px;
            transform: translateX(0px);
            width:450px;
            height:100%;
            padding:250px 60px 0;
            z-index:2;
            transition:all .3s;
            max-height:none;
            overflow: visible;
            min-height:780px;
        }
        .l2-link {
            display:block;
            font-size:24px;
            color:$primary-color;
            padding:4px 50px;
            &:hover {
                color:#fff;
            }
            @include breakpoint(large) {
                color: #FFF;
                font-size: 32px;
                line-height: 32px;
                padding:15px 0;
                transition:color .3s;
                &:hover {
                    color:$secondary-color;
                }
            }
        }
    }

    .menu-corner-content {
        display:none;
        position:absolute;
        right:0px;
        bottom:0px;
        z-index:0;
        padding:0 60px 60px;
        text-align:right;

        .section-heading {
            font-size: 48px;
            font-weight:700;
        }

        p {
            color:#fff;
        }
    }

    .social-links {
        display:flex;
        align-items:center;
        justify-content:flex-end;
        padding:28px 0 20px;
        a {
            display:flex;
            align-items:center;
            justify-content:center;
            margin:0 0 0 20px;
            color:#fff;
            font-size:24px;
            svg {
                width:24px;
                fill:#fff;
            }
        }
    }

    .search {
        display:block;
        position:relative;
        z-index:10;
        padding:30px 20px 10px;
        input {
            width:100%;
            padding:12px 20px;
            border:1px solid #D5DFE3;
            border-radius:24px;
            background:#fff;
            font-size:18px;
            height: 48px;
            &::placeholder {
                color:#CAC7C9;
            }
        }
        .button {
            position:absolute;
            right:20px;
            top:30px;
            padding:15px 22px;
            height:48px;
        }
    }

    .mobile-search {
        display:block;
        @include breakpoint(large) {
            display:none;
        }
    }

    .desktop-search {
        display:none;
        position:absolute;
        width:500px;
        right:20px;
        top:50px;
        z-index:10;
    }

}