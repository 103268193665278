.media-player {
    position:fixed;
    z-index:20;
    bottom:20px;
    left:50%;
    transform:translateX(-50%);
    width:calc(100% - 30px);
    height:75px;
    border-radius:20px;
    padding:0px 16px;
    background:rgba(92,94,97,.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    transition:.4s all;
    max-width:1800px;

    // Figma styles
    // fill: rgba(119, 119, 119, 0.50);
    // backdrop-filter: blur(25px);
    @include breakpoint(medium){
        padding:0px 20px;
    }
    @include breakpoint(large){
        padding:0px 30px;
        width:calc(100% - 50px);
    }
    @include breakpoint(xxlarge){
        width:calc(100% - 100px);
    }
    .full-player {
        opacity:0;
        height:0px;
        transition:.3s all;
        transition-delay:.4s;
        overflow: hidden;
    }

    &.expanded {
        height:calc(100vh - 110px);
        padding:0px;
        .mini-player {
            opacity:0;
            height:0px;
        }
        .full-player {
            opacity:1;
            height:100%;
        }
        .mobile-player {
            opacity:1;
            height:100%;
        }
    }

    .mini-player {
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;
        height:100%;
        transition:.2s all;
        opacity:1;

        .audio-icon {
            // display:none;
            @include breakpoint(medium) {
                display:block;
            }
        }

        .button {
            display:none;
            @include breakpoint(xlarge) {
                display:inline-flex;
            }
        }
        .on-air-wrap {
            padding-right:0px;
            width:100%;
            @include breakpoint(medium) {
                width:auto;
                padding-right:20px;
            }
        }
        .show-info {
            overflow: hidden;
            @include breakpoint(large) {
                max-width: 260px;
            }
            @include breakpoint(xxlarge) {
                max-width: none;
            }
        }
        .title {
            font-weight:800;
            text-transform:uppercase;
        }
        .title, .subtitle {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .btn-skip-forward, .btn-skip-backward, .btn-jump-backward, .btn-jump-forward {
            display:none;
            @include breakpoint(medium) {
                display:flex;
            }
        }

        .podcast-scrub-bar {
            display:none;
        }

    }

    &.playing {
        .btn-play-pause.btn-play-audio {
            display:none;
        }
        .btn-play-pause.btn-pause-audio {
            display:flex;
        }
        .btn-episode-details {
            display:none;
        }
    }
    &.podcast-playing {
        .podcast-controls {
            display:flex;
        }
        .stream-controls {
            display:none;
        }
        .player-main-stream {
            display:none;
        }
        .player-main-podcast {
            display:flex !important;
        }
        .current-podcast {
            display:flex;
        }
        .current-stream {
            display:none;
        }
        .btn-recently-played {
            display:none;
        }
        .on-air-switch {
            display:inline-block;
        }
        .podcast-section-inner {
            width:calc(100% - 410px);
        }
        .btn-episode-details {
            @include breakpoint(xlarge) {
                display:flex;
                margin-left:10px;
            }
        }
        &.expanded {

            .podcast-controls {
                position:relative;
                padding-bottom:50px;
            }
            .podcast-scrub-bar {
                display:flex;
            }
        }
        .stream-current-show {
            display:none;
        }
    }
    &:not(.podcast-playing) {
        .podcast-current-show {
            display:none;
        }
    }
    .current-podcast {
        display:none;
    }

    svg {
        fill:#fff;
    }
    .controls {
        position:relative;
        // position:absolute;
        // top:50%;
        // left:50%;
        // transform:translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            transition: all .15s;
            &:hover {
                opacity: .8;
            }
        }
        svg {
            height:28px;
        }
        .btn-play-pause {
            display:flex;
            padding:0 15px;
        }
        .play {
            height:52px;
        }
        .btn-pause {display:none;}
        .btn-vote {
            display:none;
            @include breakpoint(large) {
                display:flex;
            }
            &.disabled {
                pointer-events:none;
                opacity:.5;
            }
        }

        .btn-jump-backward {
            position:relative;
            margin-right:10px;
            &:before {
                content:'10';
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%, -51%);
                font-size:12px;
                color:#fff;
                font-weight:600;
            }

        }
        .btn-jump-forward {
            position:relative;
            margin-left:10px;
            &:before {
                content:'30';
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%, -51%);
                font-size:12px;
                color:#fff;
                font-weight:600;
            }
        }

        .btn-expand-mobile {
            display:flex;
            @include breakpoint(large) {
                display:none;
            }
        }
    }
    .podcast-scrub-bar {
        display: none;
        width: 100%;
        margin: 20px auto 0;
        max-width: 300px;
        position:absolute;
        bottom:0px;
        color:#fff;
        @include breakpoint(medium) {
            max-width: 390px;
        }
        .podcast-scrub-slider {
            width: 100%;
            margin:0 10px;
            accent-color:#ffffff;
        }

        .current-time {
            min-width:40px;
        }
    }
    

    .podcast-controls {
        display:none;
        a {
            display:flex;
            justify-content:center;
        }
        .btn-jump:before {
            font-size:10px;
            top:calc(50% + 2px);
        }
        .icon-step {
            height:35px;
        }
    }

    .player-left {
        display:flex;
        align-items:center;
        justify-content:flex-start;
        width:calc(100% - 100px);
        @include breakpoint(large) {
            width:calc(50% - 110px);
        }
    }
    .player-right {
        display:none;
        align-items:center;
        justify-content:flex-end;
        width:calc(50% - 110px);

        @include breakpoint(large) {
            display:flex;
        }
        
        .btn-expand-player {
            display:flex;
            transition:all .1s;
            &:hover {
                transform:scale(1.1);
            }
        }
    }
    .button {
        margin:0px;
        background:rgba(255,255,255,.25);
        color:#fff;
        &:hover {
            background:rgba(255,255,255,.50) !important;
        }
    }
}
.on-air-wrap {
    display:flex;
    align-items:center;
    padding-right:20px;

    .show-info {
        padding-left:10px;
        .audio-icon {display:none;}
    }
    .btn-about-show {
        margin-left:20px;
    }
    .album-art {
        width:55px;
        height:55px;
    }
    .on-air-image {
        width:57px;
        height:57px;
        border-radius:50%;
    }
    .subheading {
        color: $primary-color;
        font-size: 11px;
        text-transform: uppercase;
        font-weight:700;
    }
    .title {
        display:block;
        color: #FFF;
        font-size: 18px;
        line-height:18px;
        font-weight:900;
    }
    .subtitle {
        display:block;
        color: #c3c8ce;
        margin-top:4px;
        margin-bottom:0px;
        font-size: 13px;
        line-height:14px;
        font-weight:300;
        font-family:$font-family-secondary;
        font-style:italic;
        a {
            color: #c3c8ce;
            &:hover {
                text-decoration:underline;
            }    
        }
    }
    a.title {
        &:hover {
            text-decoration:underline;
        }
    }
}

.vote-tooltip {
    display:none;
    position:absolute;
    top:-110px;
    left:calc(50% - 105px);
    width:210px;
    background:#fff;
    padding:20px 12px 10px;
    border-radius:5px;
    z-index:40;
    box-shadow:0px 0px 10px rgba(0,0,0,.25);
    // Write a native css media query for a max-width of 600px
    @media (max-width: 1024px) {
        transform:none !important;
    }

    p {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 5px;
    }

    .btn-close-tooltip {
        position: absolute;
        top: 0px;
        right: 0px;
        padding:0 6px;
        cursor: pointer;
        font-size: 18px;
        line-height:22px;
        color: #000;
    }

    // Add a triangle to the bottom of the tooltip using a pseudo element
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        display:none;
        @include breakpoint(large) {
            display:block;
        }
    }
}


$speed: 1s;

.audio-icon {
    width:37px;
    min-width:37px;
    fill:#fff;
    @include breakpoint(medium) {
        margin-right:15px;
    }
    @include breakpoint(small down) {
        width:35px;
        min-width: 35px;
        path:nth-child(n+6) {
            display:none;
        }
    }
}

// .audio-icon {
//     height: 30px;
//     @include breakpoint(small down) {
//         width:30px;
//         path:nth-child(n+5) {
//             display:none;
//         }
//     }
//     @include breakpoint(medium) {
//         margin-right:20px;
//     }

//   path {
//     transform-origin: center;
//     fill: none;
//     stroke: #fff;
//     stroke-linecap: round;
//     stroke-linejoin: round;
//     stroke-width: 2;
//     transition: 0.5s;
//   }

// }

.media-player.playing {
    @for $i from 1 through 9 {
        #Line_#{$i} {
            animation: pulse .6s infinite;
            animation-delay: $i * .15s;
        }
    }
    
    @keyframes pulse {
        0% {
            transform: scaleY(1);
            transform-origin: 50% 50%;
        }
        
        50% {
            transform: scaleY(.7);
            transform-origin: 50% 50%;
        }
        
        100% {
            transform: scaleY(1);
            transform-origin: 50% 50%;
        }
    }
    // #stroke1 {
    //     animation: strokeA $speed ease infinite;
    //     @keyframes strokeA {
    //       0% {
    //         d: path("M1 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M1 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M1 10.064 l0 3");
    //       }
    //     }
    //   }
  
    //   #stroke2 {
    //     animation: strokeB $speed ease infinite;
    //     animation-delay: 0.125s;
    //     @keyframes strokeB {
    //       0% {
    //         d: path("M5.162 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M5.162 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M5.162 10.064 l0 3");
    //       }
    //     }
    //   }
  
    //   #stroke3 {
    //     animation: strokeC $speed ease infinite;
    //     animation-delay: 0.25s;
    //     @keyframes strokeC {
    //       0% {
    //         d: path("M9.325 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M9.325 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M9.325 10.064 l0 3");
    //       }
    //     }
    //   }
  
    //   #stroke4 {
    //     animation: strokeD $speed ease infinite;
    //     animation-delay: 0.5s;
    //     @keyframes strokeD {
    //       0% {
    //         d: path("M13.487 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M13.487 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M13.487 10.064 l0 3");
    //       }
    //     }
    //   }
  
    //   #stroke5 {
    //     animation: strokeE $speed ease infinite;
    //     animation-delay: 0.25s;
    //     @keyframes strokeE {
    //       0% {
    //         d: path("M17.65 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M17.65 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M17.65 10.064 l0 3");
    //       }
    //     }
    //   }
  
    //   #stroke6 {
    //     animation: strokeF $speed ease infinite;
    //     animation-delay: 0.4s;
    //     @keyframes strokeF {
    //       0% {
    //         d: path("M21.82 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M21.82 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M21.82 10.064 l0 3");
    //       }
    //     }
    //   }
  
    //   #stroke7 {
    //     animation: strokeG $speed ease infinite;
    //     animation-delay: 0.25s;
    //     @keyframes strokeG {
    //       0% {
    //         d: path("M25.974 10.064 l0 3");
    //       }
    //       50% {
    //         d: path("M25.974 1 l0 21.034");
    //       }
    //       100% {
    //         d: path("M25.974 10.064 l0 3");
    //       }
    //     }
    //   }
  
}