.date-tile {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:55px;
    height:60px;
    padding:10px;
    background:#fff;
    border-radius:10px;
    text-align: center;
    font-weight:900;
    
    &.hope {
        background-color:#E6E9EC;
        .month, .day {
            color:$hope;
        }
    }
    &.prayer {
        background-color:#E6E9EC;
        .month, .day {
            color:$prayer;
        }
    }

    .month {
        color: $brand-dark;
        font-size: 12px;
        line-height: 12px;
        text-transform:uppercase;
    }
    .day {
        color: $brand-dark;
        font-size: 28px;
        line-height: 28px;
    }

    &.large {
        @include breakpoint(large) {
            width:87px;
            height:98px;
            padding:20px 0;
            .month {
                font-size: 20px;
                line-height: 20px;
            }
            .day {
                font-size: 46px;
                line-height: 46px;
            }
        }
    }
}

