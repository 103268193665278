.EventsPage {
    .featured-event {
        margin-bottom:60px;
    }

    .search-form {
        position:relative;
        padding-right:90px;
        input {
            box-shadow:none;
            border-radius:30px 0px 0px 30px;
            padding-left:20px;
        }
        .button {
            position:absolute;
            right:0px;
            top:0px;
            width:110px;
            height:44px;
        }
    }

    .filter-dropdown {
        width:calc(50% - 3px);
        font-weight:900;
        @include breakpoint(medium) {
            width:calc(50% - 3px);
        }
        @include breakpoint(large) {
            width:240px;
        }
    }

    .page-header.event-detail-header {

        .date-tile {
            display:none;
            flex:none;
            margin-right:20px;
            @include breakpoint(large) {
                display:flex;
            }
        }
        h1 {
            @include breakpoint(medium) {
                font-size: 50px;
                line-height: 50px;
            }
        }
        .event-details {
            margin:5px 0;
        }

        .title-details-wrap {
            @include breakpoint(large) {
                padding-right:220px;
            }
        }

        .page-header-content {
            @include breakpoint(large) {
                display:flex;
            }
        }
        .image-wrap {
            height: 300px;
            background-size: cover;
            background-position: center;
            @include breakpoint(medium) {
                height: 450px;
            }
            @include breakpoint(large) {
                height: 650px;
            }
        }

        .button {
            position: relative;
            right:unset;
            bottom:unset;
            @include breakpoint(large) {
                position: absolute;
                right:30px;
                bottom:30px;
            }
        }
    }
    .event-details-content {
        p, ul, ol {
            font-size: 20px;
            line-height: 32px;
        }
    }

}

.event-details-content {
    p, ul, ol {
        font-family: $font-family-secondary;
    }
}
.tickets-tile {
    @include tile-padding;
    @include rounded-corners;
    @include box-shadow;
    background:#3d4247;
}

.event-tile {
    display:block;
    position: relative;
    padding-left:85px;
    padding:15px 20px 15px 105px;
    @include breakpoint(medium) {
        padding:20px 30px 20px 105px;
    }
    padding-left:100px;
    @include rounded-corners;
    @include box-shadow;
    transition:all .2s;
    background:#3D4247;

    &:hover {
        transform:scale(1.01);
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
    }

    &.brand-dark {
        .category {color:$body-light;}
        .date {
            background:$brand-dark;
            color: #fff;
        }
    }
    &.brand-medium {
        .category {color:#fc9260;}
        .date {
            background: #fc9260;
            color: #fff;
        }
    }
    &.brand-primary {
        .category {color:$primary-color;}
        .date {
            background: $primary-color;
            color: #fff;    
        }
    }

    .date {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background:$brand-dark;
        width:87px;
        
        .day {
            display: block;
            font-size: 34px;
            line-height: 24px;
            font-weight:900;
            color: #fff;
        }
        .month {
            display: block;
            text-transform:uppercase;
            font-size: 24px;
            line-height: 36px;
            font-weight:900;
            color: #fff;
        }
    }
    .category {
        font-size:12px !important;
        line-height: 14px;
        font-weight:900;
        margin-bottom:5px !important;
        text-transform: uppercase;
        color: $brand-dark;
    }
    .title {
        font-size: 24px;
        line-height: 26px;
        font-weight:800;
        @include breakpoint(medium) {
            font-size: 28px;
            line-height: 30px;
        }
        @include breakpoint(xxxlarge) {
            font-size: 32px;
            line-height: 36px;
        }
    }
    .description {
        font-family: $font-family-secondary;
        color:$body-light;
    }
    .time {
        font-size:24px;
    }
    .location, .address {
        font-size:18px;
        line-height: 20px;
        margin-bottom:5px !important;
        color:$white;
        font-family: $font-family-sans-serif;
    }
    .location {
        font-size:20px !important;
    }

    &.brand-primary {
        padding:0px;

        .content {
            padding:110px 20px 20px 20px;
            @include breakpoint(medium) {
                padding:110px 30px 30px 30px;
            }

            &.has-image {
                padding-top:22px;
            }
        }

        img {
            width:100%;
            padding-top:87px;
        }

        .date {
            width:100%;
            height:87px;
            flex-direction: row;

            .month, .day {
                font-size:36px;
                line-height: 36px;
                padding:4px;
                font-family:$font-family-primary;
            }
        }
    }
}

#event-form {
    .heading {
        width:100%;
    }
    .event-details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom:1px solid #cacaca;
        margin-bottom:20px;
        padding-bottom: 8px;
    }
}