.page-header {
    position: relative;
    margin-bottom: 30px;
    @include breakpoint(medium) {
        margin-bottom: 50px;
    }
    .page-header-content {
        position:absolute;
        width:100%;
        left:0px;
        bottom:0px;
        z-index:2;
        @include tile-padding;

        h1 {
            font-size:36px;
            line-height:36px;
            color:#fff;
            margin:0px;
            @include breakpoint(medium) {
                font-size:60px;
                line-height:60px;
            }
        }
        p {
            font-size:18px;
            line-height:18px;
            color:#fff;
            margin:15px 0;
            @include breakpoint(medium) {
                font-size:28px;
                line-height:28px;
            }
        }
        &.d-flex {
            align-items:center;
        }
    }

    .image-wrap {
        position:relative;
        overflow:hidden;
        min-height:280px;
        max-height:350px;
        background-color: #000;
        border-radius:0 0 20px 20px;
        background-size:cover;
        background-position:center;
        @include breakpoint(large) {
            max-height:650px;
        }

        img:not(.album-art) {
            display:none;
            @include breakpoint(large) {
                display:block;
            }
        }

        &:before {
            content: '';
            border-radius: 0px 0px 20px 20px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%);
            width: 100%;
            bottom: 0px;
            left: 0px;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        &.solid, &.blur {
            background:$secondary-color;
            @include breakpoint(large) {
                min-height:650px;
            }
        }
    }

    .no-blur {
        .album-image {
            filter: none;
        }
    }

    img {
        width:100%;
        border-radius:0 0 15px 15px;
        // opacity:.5;
        @include breakpoint(medium) {
            border-radius:0 0 20px 20px;
        }

        &.album-image {
            position: absolute;
            top: 50%;
            transform: translate(-25px, -50%);
            filter: blur(17px);
            min-width: calc(100% + 50px);
        }
    }

    .album-art {
        display: none;
        border-radius:0px;
        margin-right:20px;
        @include breakpoint(medium) {
            width:170px;
            display:block;
        }
        @include breakpoint(large) {
            width:250px;
        }
    }

    .artist-link {
        color:$white;
        &:hover {
            color:$white;
            text-decoration: underline;
        }
    }

    .button {
        margin-top:20px;
        @include breakpoint(medium) {
            position:absolute;
            right:30px;
            bottom:30px;
        }
    }
}