.dnadesign__elementaluserforms__model__elementform {
    @extend .grid-container;
    @extend .narrow;
}
form {
    input[type="submit"] {
        @extend .button;
        @extend .wide;
    }
    label {
        font-weight:600;
        color:$body-light;
    }
}
select {
    border-radius: 8px;
}

.success-message {
    margin:40px 0 20px;
}


.FormHeading {
    width: 100%;
    margin:10px 0;
}
.form-step, fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    div.field {
        width: 100%;
    }

    @include breakpoint(medium) {
        .field.one-half {
            width: calc((100% / 2) - 10px);
        }
        .field.one-third {
            width: calc((100% / 3) - 10px);
        }
        .field.two-thirds {
            width: calc(67% - 10px);
        }
        .field.one-quarter {
            width: calc((100% / 4) - 10px);
        }
    }    
}


form.disabled {
    position: relative;
}

form.disabled:before {
    content: '';
    width: 100%;
    height: 100%;
    background: white;
    opacity: .5;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}
form.disabled:after {
    content: 'Submitting...';
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    padding: 20px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}


// Recaptcha Hack
.field[id*="CaptchaToken"] label {
    visibility: hidden;
    height: 0px;
}

// Material floating labels


// .userform div.field {
//     position:relative;
// }
// .userform label {
// 	position: absolute;
// 	top: 9px;
// 	left: 12px;
//     transition:all .2s;
//     font-weight:500 !important;
// }
// .userform label.active, .userform label.filled {
// 	position: absolute;
// 	top: -9px;
// 	left: 8px;
// 	font-size: 11px;
//     padding:0 4px;
//     background-color:$body-background;
// }

// .userform {
//     .dropdown label, .date label {
//         position: absolute;
//         top: -9px;
//         left: 8px;
//         font-size: 11px;
//         padding:0 4px;
//         background-color:$body-background;
//     }
// }

// .userform .checkbox label, .userform .file label {
//     position:relative !important;
//     top:0 !important;
//     left:0 !important;
//     background-color:transparent !important;
//     font-size:14px !important;
//     padding:0px !important;
// }


// .userform textarea, select, input[type="text"], input[type="date"], .userform input[type="email"], .userform input[type="tel"], .userform input[type="password"], .userform textarea {
//     border: 1px solid #ccc;
//     background-color:transparent;
//     box-sizing: border-box;
//     box-shadow:none;

//     &:focus {
//         border: 1px solid #ccc;
//         box-shadow:none;
//         background-color:transparent;
//     }
// }

.reveal {
    form {
        label {
            color:$brand-dark;
        }
    }
}